import RiskSideMenu from "../Components/RiskSideMenu";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getConnectedUser, SendPost, SendGet, SendPut, isEmpty} from "../State/Helper";
import Success from "../Components/Success";
import Error from "../Components/Error";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function CreateCountry() {

    const [isEditMode, setIsEditMode] = useState(false);
    const [name, setName] = useState('');
    const [flag, setFlag] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const navigate = useNavigate();
    const connectedUser = getConnectedUser();
    const { id } = useParams();

    // Check if the page is in create or edit mode
    useEffect(() => {
        async function fetchData() {
            if(!isEmpty(id))
            {
                const response = await SendGet(`api/v1/countries/${id}`);
                if(response.data)
                {
                    setName(response.data.name);
                    setFlag(response.data.flag);
                    setIsEditMode(true);
                }
            }
        }
        fetchData().catch(e => console.log(`Error occurred during the fetch of the country`));
    }, []);

    const createCountry = async () => {

        // Check the inputs for value before sending the request
        if( name === '' || flag === '' )
        {
            setError('Please fill all the required fields!');
            return false;
        }

        // Send the request to the backend in order to persist the data
        try
        {
            await SendPost('api/v1/countries', {
                'name': name,
                'flag': flag,
                'creatorId': connectedUser.id
            });

            setError(null);
            setSuccess('Country added successfully!');

            navigate(`/countries`);
        }
        catch(error)
        {
            setSuccess(null);
            setError('Error occurred during the action!');
        }
    }

    const updateCountry = async () => {

        // Check the inputs for value before sending the request
        if( name === '' || flag === '' )
        {
            setError('Please fill all the required fields!');
            return false;
        }

        // Send the request to the backend in order to persist the data
        try
        {
            await SendPut(`api/v1/countries/${id}`, {
                'name': name,
                'flag': flag
            });

            setError(null);
            setSuccess('Country updated successfully!');

            navigate(`/countries`);
        }
        catch(error)
        {
            setSuccess(null);
            setError('Error occurred during the action!');
        }
    }

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div style={styles.formContainer}>

                        <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                        <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Nom <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>flag</span> Flag <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={flag} onChange={e => setFlag(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={{ textAlign: 'center', margin: 20 }}>
                            <span onClick={() => navigate("/countries")} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> annuler</span>
                            { !isEditMode && <span onClick={() => createCountry()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateCountry()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
