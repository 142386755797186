import GridItem from "./GridItem";
import {getColorHexCode} from "../State/Helper";

export default function OccurrenceImpactShape({ data }) {

    const styles = {
        container: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: 20
        },
        sectionContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            border: 'solid 2px #DAE8FA',
            borderRadius: 10,
            cursor: 'pointer'
        },
        sectionTitle: {
            fontSize: 16,
            textTransform: 'uppercase',
            fontWeight: 700,
            marginTop: 35,
            color: '#000000',
            flex: 2
        },
        sectionImage: {
            height: 50,
            width: 80,
            padding: 20
        },
        sectionIcon: {
            color: '#000000',
            fontSize: 30,
            fontWeight: 700,
            verticalAlign: 'middle',
            marginTop: 30,
            marginRight: 10
        },
        mainContainer: {
            backgroundColor: '#FFFFFF',
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
        },
        chartContainer: {
            margin: 40,
            marginBottom: 0,
            marginLeft: 5,
            borderLeft: 'solid 5px #094492',
            borderTop: 'none',
            borderRight: 'none',
            background: `linear-gradient(to top right, ${ getColorHexCode(1) }, ${ getColorHexCode(4) }, ${ getColorHexCode(5) }, ${ getColorHexCode(6) })`,
        },
        chartRow: {
            height: 50,
            display: 'flex',
            flexDirection: 'row',
            flex: 0.8,
        },
        chartColumn: {
            flex: 0.8,
            border: 'solid 0.2px #C9A457'
        },
        verticalNumbering: {
            flex: 1,
            color: '#000000',
            fontWeight: 600,
            paddingTop: 30
        },
        horizontalNumbering: {
        },
        chartCircle: {
            backgroundColor: '#FFFFFF', width: 80, height: 80, borderRadius: '50%', marginLeft: -40, zIndex: 9999
        }
    }

    return (

        <div style={{ backgroundColor: '#FFFFFF', display: 'flex', flex: 1, margin: '0px auto' }}>

            <div style={{ flex: 1, flexDirection: 'row', margin: 20, display: 'flex', position: 'relative' }}>

                <div style={{ flex: 0.6, display: 'flex', flexDirection: 'row', marginTop: 50 }}>

                    <div style={{ textAlign: "center" }}>
                        <p style={{ color: '#000000', fontSize: 20, fontWeight: 600, verticalAlign: 'middle', transform: "rotate(-90deg)", marginTop: 200, flex: 1}}>OCCURRENCE</p>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', height: 600 }}>
                        <p style={styles.verticalNumbering}>6</p>
                        <p style={styles.verticalNumbering}>5</p>
                        <p style={styles.verticalNumbering}>4</p>
                        <p style={styles.verticalNumbering}>3</p>
                        <p style={styles.verticalNumbering}>2</p>
                        <p style={styles.verticalNumbering}>1</p>
                    </div>

                </div>

                <div style={{ flex: 5, display: 'flex', flexDirection: 'column' }}>

                    <div style={{ position: 'relative', marginTop: 50 }} >

                        {
                            data.map((item, index) => (
                                <GridItem
                                    key={index}
                                    occurrence={item.occurrence}
                                    impact={item.impact}
                                />
                            ))
                        }

                        <div style={{ flex: 1, display: 'flex', borderLeft: 'solid 8px #000000', }}>
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(5), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(6), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(6), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(6), border: 'solid 1px #FFFFFF' }} />
                        </div>

                        <div style={{ flex: 1, display: 'flex', borderLeft: 'solid 8px #000000', }}>
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(5), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(5), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(6), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(6), border: 'solid 1px #FFFFFF' }} />
                        </div>

                        <div style={{ flex: 1, display: 'flex', borderLeft: 'solid 8px #000000', }}>
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(5), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(5), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(6), border: 'solid 1px #FFFFFF' }} />
                        </div>

                        <div style={{ flex: 1, display: 'flex', borderLeft: 'solid 8px #000000', }}>
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(5), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(5), border: 'solid 1px #FFFFFF' }} />
                        </div>

                        <div style={{ flex: 1, display: 'flex', borderLeft: 'solid 8px #000000', }}>
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                        </div>

                        <div style={{ display: 'flex', width: 912, borderBottom: 'solid 8px #000000', borderLeft: 'solid 8px #000000' }}>
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(1), border: 'solid 1px #FFFFFF' }} />
                            <div style={{ width: 150, height: 100, backgroundColor: getColorHexCode(4), border: 'solid 1px #FFFFFF' }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', marginTop: 10, width: 900 }}>
                        <div style={{ width: 150, height: 100, textAlign: 'center', color: '#000000', fontWeight: 600 }}>1</div>
                        <div style={{ width: 150, height: 100, textAlign: 'center', color: '#000000', fontWeight: 600 }}>2</div>
                        <div style={{ width: 150, height: 100, textAlign: 'center', color: '#000000', fontWeight: 600 }}>3</div>
                        <div style={{ width: 150, height: 100, textAlign: 'center', color: '#000000', fontWeight: 600 }}>4</div>
                        <div style={{ width: 150, height: 100, textAlign: 'center', color: '#000000', fontWeight: 600 }}>5</div>
                        <div style={{ width: 150, height: 100, textAlign: 'center', color: '#000000', fontWeight: 600 }}>6</div>
                    </div>

                    <div style={{ marginLeft: '30%' }}>
                        <p style={{ color: '#000000', fontSize: 20, fontWeight: 600,  marginTop: -50 }}>IMPACT</p>
                    </div>

                </div>

            </div>
        </div>
    );
}
