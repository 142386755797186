import React, { useState } from 'react';
import RiskCard from "./RiskCard";
import { primaryColor, lighGrey } from "../configuration/colorPalet";
import {useAuth} from "../State/useAuth";

const Paginator = ({ items, itemsPerPage }) => {
    // State to track current page
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate total pages
    const totalPages = Math.ceil(items.length / itemsPerPage);

    // Calculate the slice of items to display
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);

    // Function to change page
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const styles = {
        cardRow: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row'
        },
        paginatorContainer: {
            margin: 20
        },
        paginationBtn: {
            color: '#FFFFFF',
            backgroundColor: primaryColor,
            padding: 5,
            border: `solid 1px ${primaryColor}`,
            borderRadius: 4,
            margin: 2,
            cursor: 'pointer',
            paddingLeft: 5,
            paddingRight: 5,
            fontWeight: 700
        },
        paginationBtnDisabled: {
            color: primaryColor,
            backgroundColor: lighGrey,
            padding: 5,
            border: `solid 1px ${lighGrey}`,
            borderRadius: 4,
            margin: 2,
            paddingLeft: 5,
            paddingRight: 5,
            cursor: 'pointer',
            fontWeight: 700
        }
    }

    return (
        <div>

            <div style={styles.cardRow}>
                {currentItems.map((item, index) => (
                    <RiskCard key={index} risk={item} />
                ))}
            </div>

            <div style={styles.paginatorContainer}>

                <button style={{ ...(currentPage === 1 ? styles.paginationBtnDisabled : styles.paginationBtn) }} onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
                    Précédent
                </button>

                {[...Array(totalPages).keys()].map(pageNumber => (
                    <button style={{ ...(currentPage === pageNumber + 1 ? styles.paginationBtnDisabled : styles.paginationBtn) }} key={pageNumber + 1} onClick={() => goToPage(pageNumber + 1)} disabled={currentPage === pageNumber + 1}>
                        {pageNumber + 1}
                    </button>
                ))}

                <button style={{ ...(currentPage === totalPages ? styles.paginationBtnDisabled : styles.paginationBtn) }} onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
                    Suivant
                </button>
            </div>
            
        </div>
    );
};

export default Paginator;
