import RiskSideMenu from "../Components/RiskSideMenu";
import frenchLogo from "../assets/images/french_logo.png";
import britainLogo from "../assets/images/britain.png";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SendGet} from "../State/Helper";

export default function CreateCartographie() {

    const navigate = useNavigate();
    const [nomFrancais, setNomFrancais] = useState('');
    const [nomAngalis, setNomAnglais] = useState('');
    const [businessUnit, setBusinessUnit] = useState('');
    const [prefixRisque, setPrefixRisque] = useState('');
    const [categorie, setCategorie] = useState('');
    const [gestionnaire, setGestionnaire] = useState('');
    const [themeFrancais, setThemeFrancais] = useState('');
    const [themeAnglais, setThemeAnglais] = useState('');
    const [consequenceFrancais, setConsequenceFrancais] = useState('');
    const [consequenceAngalis, setConsequenceAnglais] = useState('');

    const [showIconContainer, setShowIconContainer] = useState(false);
    const [iconsList] = useState([
        {
            name: 'home'
        },
        {
            name: 'search'
        },
        {
            name: 'menu'
        },
        {
            name: 'close'
        },
        {
            name: 'settings'
        },
        {
            name: 'done'
        },
        {
            name: 'expand_more'
        },
        {
            name: 'favorite'
        }
    ]);
    const [businessUnits, setBusinessUnits] = useState([]);
    const [users, setUsers] = useState([]);

    const getBusinessUnits = async () => {
        try
        {
            const response = await SendGet('api/v1/bus');
            setBusinessUnits(response.data);
        }
        catch(Error)
        {
            console.log(Error);
        }
    }

    const getUsers = async () => {
        try
        {
            const response = await SendGet('api/v1/users');
            setUsers(response.data);
        }
        catch(Error)
        {
            console.log(Error);
        }
    }

    useEffect(() => {
        async function fetch() {
            await getBusinessUnits();
            await getUsers();
        }
        fetch();
    }, []);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',

        },
        menuCol: {
            flex: 1
        },
        bodyCol: {
            flex: 5,
            paddingLeft: 20,
        },
        mainTitle: {
            color: '#67D0FA',
            fontSize: 34
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            padding: 20,
            border: 'solid 1px #D1E4FE',
            borderRadius: 20,
            width: 800,
            paddingBottom: 50,
            marginBottom: 50
        },
        formRow: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 40
        },
        textLabel: {
            textTransform: 'uppercase',
            fontSize: 16,
            color: '#67D0FA'
        },
        flagIcon: {
            width: 20,
            height: 15,
            marginRight: 5,
            borderRadius: 5,
            verticalAlign: 'middle'
        },
        requiredStar: {
            color: 'red',
            fontSize: 15
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        iconStyle: {
            fontSize: 20,
            verticalAlign: 'middle'
        },
        doubleSectionRow: {
            display: 'flex',
            flexDirection: 'row'
        },
        seperator: {
            backgroundColor: '#D1E4FE',
            height: 1,
            marginBottom: 30
        },
        primaryButton: {
            backgroundColor: '#62CFFC',
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: 700,
            padding: 10,
            borderRadius: 20,
            width: 250,
            marginTop: 20,
            textAlign: 'center',
            cursor: 'pointer',
            textTransform: 'uppercase'
        },
        primaryLabel: {
            textTransform: 'uppercase',
            fontSize: 16,
            color: '#67D0FA',
            marginBottom: 20
        },
        buttonRowContainer: {
            textAlign: 'center',
            marginBottom: 50
        },
        choisirIcon: {
            backgroundColor: '#094392',
            color: '#FFFFFF',
            padding: 10,
            fontSize: 14,
            cursor: 'pointer',
            borderRadius: 30,
            width: 200,
            textTransform: 'uppercase',
            textAlign: 'center',
            margin:  '0 auto'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            padding: 15,
            fontSize: 14,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            cursor: 'pointer',
            textDecoration: 'none'
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 14,
            padding: 15,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            cursor: 'pointer',
            textDecoration: 'none'
        },
        btnContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        iconContainer: {
            padding: 10,
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 40
        },
        iconListStyle: {
            fontSize: 22,
            color: '#094392',
            flex: 0.2,
            verticalAlign: 'middle',
            marginRight: 10
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.menuCol}>
                <RiskSideMenu showSideMenu={true} />
            </div>
            <div style={styles.bodyCol}>
                <p style={styles.mainTitle}>Nouvelle cartographie</p>
                <div style={styles.formContainer}>
                    <div style={styles.formRow}>
                        <span style={styles.textLabel}><img src={frenchLogo} style={styles.flagIcon}  alt="french"/> nom français <span style={styles.requiredStar}>*</span></span>
                        <input value={nomFrancais} type="text" style={styles.inputField} onChange={e => setNomFrancais(e.target.value)} />
                    </div>
                    <div style={styles.formRow}>
                        <span style={styles.textLabel}><img src={britainLogo} style={styles.flagIcon}  alt="french"/> nom anglais <span style={styles.requiredStar}>*</span></span>
                        <input value={nomAngalis} type="text" style={styles.inputField} onChange={e => setNomAnglais(e.target.value)} />
                    </div>
                    <div style={styles.doubleSectionRow}>
                        <div style={{ ...styles.formRow, flex: 3, marginRight: 15 }}>
                            <span style={styles.textLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>fork_right</span> Business Unit</span>
                            <select value={businessUnit} style={styles.selectField} onChange={e => setBusinessUnit(e.target.value)}>
                                <option value=""></option>
                                {
                                    businessUnits.map(bu => {
                                        return (
                                            <option key={bu.id} value={bu.id}>{bu.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div style={{ ...styles.formRow, flex: 3 }}>
                            <span style={styles.textLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>bookmark</span> péfixe de risque <span style={styles.requiredStar}>*</span></span>
                            <select value={prefixRisque} style={styles.selectField} onChange={e => setPrefixRisque(e.target.value)}>
                                <option value="">Test</option>
                            </select>
                        </div>
                    </div>
                    <div style={styles.doubleSectionRow}>
                        <div style={{ ...styles.formRow, flex: 3, marginRight: 15 }}>
                            <span style={styles.textLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>widgets</span> catégorie</span>
                            <select value={categorie} style={styles.selectField} onChange={e => setCategorie(e.target.value)}>
                                <option value="">Test</option>
                            </select>
                        </div>
                        <div style={{ ...styles.formRow, flex: 3 }}>
                        </div>
                    </div>
                    <div style={styles.seperator}></div>
                    <div style={styles.doubleSectionRow}>
                        <div style={{ ...styles.formRow, flex: 3, marginRight: 15 }}>
                            <span style={styles.textLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>person_add</span> gestionnaires de cartographie</span>
                            <select value={gestionnaire} style={styles.selectField} onChange={e => setGestionnaire(e.target.value)}>
                                <option value=""></option>
                                {
                                    users.map(user => {
                                        return (
                                            <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div style={{ ...styles.formRow, flex: 3 }}>
                            <a style={styles.primaryButton}>AJOUTER CE GESTIONNAIRE</a>
                        </div>
                    </div>
                    <div style={styles.seperator}></div>
                    <div style={styles.formRow}>
                        <span style={styles.primaryLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>sell</span> thèmes <span style={styles.requiredStar}>*</span></span>
                        <span style={styles.textLabel}><img src={frenchLogo} style={styles.flagIcon}  alt="french"/> titre français</span>
                        <input value={themeFrancais} type="text" style={styles.inputField} onChange={e => setThemeFrancais(e.target.value)} />
                    </div>
                    <div style={styles.formRow}>
                        <span style={styles.textLabel}><img src={britainLogo} style={styles.flagIcon}  alt="french"/> titre anglais</span>
                        <input value={themeAnglais} type="text" style={styles.inputField} onChange={e => setThemeAnglais(e.target.value)} />
                    </div>
                    <div style={styles.buttonRowContainer}>
                        <a style={styles.primaryButton}><span className="material-symbols-outlined" style={styles.iconStyle}>add</span> ajouter ce thème</a>
                    </div>
                    <div style={styles.seperator}></div>
                    <div style={styles.formRow}>
                        <span style={styles.primaryLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>sell</span> Conséquences potentielles  <span style={styles.requiredStar}>*</span></span>
                        <span style={styles.textLabel}><img src={frenchLogo} style={styles.flagIcon}  alt="french"/> titre français</span>
                        <input value={consequenceFrancais} type="text" style={styles.inputField} onChange={e => setConsequenceFrancais(e.target.value)} />
                    </div>
                    <div style={styles.formRow}>
                        <span style={styles.textLabel}><img src={britainLogo} style={styles.flagIcon}  alt="french"/> titre anglais</span>
                        <input value={consequenceAngalis} type="text" style={styles.inputField} onChange={e => setConsequenceAnglais(e.target.value)} />
                    </div>
                    <div style={styles.formRow}>
                        <span style={styles.primaryLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>local_fire_department</span> icone</span>
                        <span onClick={() => setShowIconContainer(!showIconContainer)} style={{ ...styles.choisirIcon, marginBottom: 20 }}>choisir une icone { showIconContainer ? <span className="material-symbols-outlined" style={styles.iconStyle}>expand_less</span> : <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span> }</span>
                        {
                            showIconContainer &&
                            <div style={styles.iconContainer}>
                                {
                                    iconsList.map(icon => {
                                        return (
                                            <span>
                                                <input type="radio" name="icon" style={{ verticalAlign: 'middle' }} />
                                                <span className="material-symbols-outlined" style={styles.iconListStyle}>{icon.name}</span>
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        }
                        <span style={{ ...styles.primaryButton, width: 400, margin: '0 auto' }}><span className="material-symbols-outlined" style={styles.iconStyle}>add</span> ajouter cette conséquence potentielle</span>
                    </div>
                    <div style={styles.btnContainer}>
                        <div style={{ flex: 3, textAlign: 'center' }}>
                            <span onClick={() => navigate('/cartographie')} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> annuler</span>
                        </div>
                        <div style={{ flex: 3, textAlign: 'center' }}>
                            <span style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
