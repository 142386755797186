import React from 'react';
import { useNavigate } from 'react-router-dom';

const Details = ({ data, isVisible, handleClose }) => {

    const navigate = useNavigate();

    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const contentStyle = {
        background: '#FFFFFF',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        textAlign: 'left',
        position: 'relative',
        color: '#000000'
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '18px',
        cursor: 'pointer',
    };

    return (
        isVisible && (
            <div style={overlayStyle} >
                <div style={contentStyle}>
                  <span style={closeButtonStyle} onClick={handleClose}>&times;</span>
                  <p><b>Risk title: </b> <b style={{ color: '#767676' }} onClick={() => navigate(`/risks/detail/${data.risk.id}`)}>{data.risk.frTitle}</b></p>
                  <p><b>Risk origin: </b> {data.risk.origin}</p>
                  <p><b>Risk Brut: </b> {parseFloat(data.riskBruteNormalise).toFixed(1)}</p>
                  <p><b>Risk Net: </b> {parseFloat(data.riskNetNormalise).toFixed(1)}</p>
                  <p><b>Impact: </b> {parseFloat(data.impact).toFixed(1)}</p>
                  <p><b>Maitrise: </b> {parseFloat(data.maitrise).toFixed(1)}</p>
                </div>
            </div>
        )
    );
};

export default Details;
