import RiskSideMenu from "../Components/RiskSideMenu";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Success from "../Components/Success";
import Error from "../Components/Error";
import {getConnectedUser, SendPost, SendGet, SendPut, isEmpty} from "../State/Helper";

export default function CreateTheme() {

    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [frTitle, setFrTitle] = useState('');
    const [enTitle, setEnTitle] = useState('');
    const [mappingId, setMappingId] = useState('');
    const [creatorId, setCreatorId] = useState('');
    const [mappings, setMappings] = useState([]);

    const navigate = useNavigate();
    const connectedUser = getConnectedUser();
    const { id } = useParams();

    // Check if the page is in create or edit mode
    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/themes/${id}`);

                if(response.data)
                {
                    setFrTitle(response.data.frTitle);
                    setEnTitle(response.data.enTitle);
                    setMappingId(response.data.mappingId);
                    setCreatorId(response.data.creatorId);

                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during the fetching of the theme.`));
    }, []);

    // Get Mappings
    const getMappings = async () => {
        try
        {
            const response = await SendGet(`api/v1/mappings`);
            setMappings(response.data);
        }
        catch(error)
        {
            setSuccess(null);
            setError('Error occurred during the action!');
        }
    }

    useEffect(() => {
        getMappings().catch(e => console.log(e));
    }, []);

    const createTheme = async () => {

        // Check the inputs for value before sending the request
        if( frTitle === '' || enTitle === '' || mappingId === '' )
        {
            setError('Please fill all the required fields!');
            return false;
        }

        // Send the request to the backend in order to persist the data
        try
        {
            await SendPost('api/v1/themes', {
                'frTitle': frTitle,
                'enTitle': enTitle,
                'mappingId': mappingId,
                'creatorId': connectedUser.id
            });

            setError(null);
            setSuccess('Theme added successfully!');
        }
        catch(error)
        {
            setSuccess(null);
            setError('Error occurred during the action!');
        }
    }

    const updateTheme = async () => {

        // Check the inputs for value before sending the request
        if( frTitle === '' || enTitle === '' || mappingId === '' )
        {
            setError(`Veuillez remplir tous les champs obligatoires!`);
            return false;
        }

        // Send the request to the backend in order to persist the data
        try
        {
            await SendPut(`api/v1/themes/${id}`, {
                'frTitle': frTitle,
                'enTitle': enTitle,
                'mappingId': mappingId,
                'creatorId': creatorId
            });

            setError(null);
            setSuccess('Theme updated successfully!');
        }
        catch(error)
        {
            setSuccess(null);
            setError('Error occurred during the action!');
        }
    }

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #D1E5FE',
            borderRadius: 10,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#67D0FA'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            padding: 15,
            fontSize: 14,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            cursor: 'pointer',
            textDecoration: 'none',
            border: 'none'
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 14,
            padding: 15,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            cursor: 'pointer',
            textDecoration: 'none',
            marginRight: 20,
            border: 'none'
        }
    }

    return (
        <div>
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                        <span style={styles.mainTitle}>Créer un theme</span>
                    </div>
                    <div style={styles.formContainer}>

                        <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                        <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> FR Title <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={frTitle} onChange={e => setFrTitle(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> EN Title <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={enTitle} onChange={e => setEnTitle(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>public</span> Mapping <span style={styles.requiredLabel}>*</span></span>
                            <select style={styles.selectField} value={mappingId} onChange={e => setMappingId(e.target.value)}>
                                <option value="" defaultValue=""></option>
                                {
                                    mappings.map(item => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div style={{ textAlign: 'center', margin: 20 }}>
                            <span onClick={() => navigate("/themes")} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> annuler</span>
                            { !isEditMode && <span onClick={() => createTheme()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateTheme()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
