import RiskSideMenu from "../Components/RiskSideMenu";
import frenchLogo from "../assets/images/french_logo.png";
import britainLogo from "../assets/images/britain.png";
import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";

export default function CreateActivity() {

    const [isEditMode, setIsEditMode] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        async function fetch() {
            if(id)
            {
                setIsEditMode(true);
            }
        }
        fetch();
    }, []);

    const styles = {
        mainContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        sideMenuContainer: {

        },
        contentContainer: {
            flex: 5,
            display: 'flex',
            flexDirection: 'column',
            padding: 20
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        primaryLabel: {
            fontSize: 28,
            color: '#67D0FA',
            flex: 1
        },
        iconStyle: {
            fontSize: 20,
            fontWeight: 700,
            verticalAlign: 'middle',
            cursor: 'pointer'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            margin: 20,
            padding: 40,
            borderRadius: 10,
            border: 'solid 1px #D1E5FE'
        },
        formControlContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20
        },
        flagIcon: {
            width: 20,
            height: 15,
            marginRight: 5,
            borderRadius: 5,
            verticalAlign: 'middle'
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        textLabel: {
            textTransform: 'uppercase',
            fontSize: 16,
            color: '#67D0FA'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            padding: 15,
            fontSize: 14,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            cursor: 'pointer',
            textDecoration: 'none',
            border: 'none'
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 14,
            padding: 15,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            cursor: 'pointer',
            textDecoration: 'none',
            marginRight: 20,
            border: 'none'
        }
    }

    return (
        <div style={styles.mainContainer}>
            <div style={styles.sideMenuContainer}>
                <RiskSideMenu showSideMenu={true} />
            </div>
            <div style={styles.contentContainer}>
                <div style={styles.headerContainer}>
                    { !isEditMode && <span style={styles.primaryLabel}>Nouvelle activité opérationnelle</span> }
                    { isEditMode && <span style={styles.primaryLabel}>Modification de l'activité opérationnelle</span> }
                </div>
                <div style={styles.formContainer}>
                    <div style={styles.formControlContainer}>
                        <span style={styles.textLabel}><img src={frenchLogo} style={styles.flagIcon}  alt="french"/> nom français </span>
                        <input type="text" style={styles.inputField} />
                    </div>
                    <div style={styles.formControlContainer}>
                        <span style={styles.textLabel}><img src={britainLogo} style={styles.flagIcon}  alt="french"/> nom anglais </span>
                        <input type="text" style={styles.inputField} />
                    </div>
                    <div style={styles.formControlContainer}>
                        <span style={styles.textLabel}> Branche </span>
                        <select style={styles.selectField}>
                            <option>Irrigation</option>
                        </select>
                    </div>
                    <div style={styles.buttonContainer}>
                        <button style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> annuler</button>
                        { !isEditMode && <button style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</button> }
                        { isEditMode && <button style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</button> }
                    </div>
                </div>
            </div>
        </div>
    );
}
