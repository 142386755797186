import RiskSideMenu from "../Components/RiskSideMenu";
import Success from "../Components/Success";
import Error from "../Components/Error";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getConnectedUser, isEmpty, SendGet, SendPost, SendPut} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function CreateRiskAssessment() {

    const [isEditMode, setIsEditMode] = useState(false);

    const [financialImpactWeight, setFinancialImpactWeight] = useState('');
    const [imageImpactWeight, setImageImpactWeight] = useState('');
    const [criminalImpactWeight, setCriminalImpactWeight] = useState('');
    const [netRiskFormulaBase36, setNetRiskFormulaBase36] = useState('');
    const [grossRiskFormulaBase36, setGrossRiskFormulaBase36] = useState('');
    const [netRiskFormulaBase6, setNetRiskFormulaBase6] = useState('');
    const [grossRiskFormulaBase6, setGrossRiskFormulaBase6] = useState('');
    const [formulaBase, setFormulaBase] = useState(6);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const navigate = useNavigate();
    const connectedUser = getConnectedUser();
    const { id } = useParams();

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1,
            backgroundColor: '#FFFFFF',
            padding: 10
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        }
    }

    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/riskassessment/${id}`);

                if(response.data)
                {
                    setFinancialImpactWeight(response.data.financialImpactWeight);
                    setImageImpactWeight(response.data.imageImpactWeight);
                    setCriminalImpactWeight(response.data.criminalImpactWeight);

                    setNetRiskFormulaBase36(response.data.netRiskFormulaBase36);
                    setGrossRiskFormulaBase36(response.data.grossRiskFormulaBase36);

                    setNetRiskFormulaBase6(response.data.netRiskFormulaBase6);
                    setGrossRiskFormulaBase6(response.data.grossRiskFormulaBase6);

                    setFormulaBase(response.data.formulaBase);

                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during the getting of the risk assessment.`, e));
    }, []);

    const createRiskAssessment = async () => {

        // Check the inputs for value before sending the request
        if(
            financialImpactWeight === '' ||
            imageImpactWeight === '' ||
            criminalImpactWeight === '')
        {
            setError('Veuillez remplir tous les champs obligatoires !');
            return false;
        }

        if( formulaBase === 6 )
        {
            if( netRiskFormulaBase6 === '' || grossRiskFormulaBase6 === '' )
            {
                setError('Veuillez remplir tous les champs obligatoires !');
                return false;
            }
        }
        else if( formulaBase === 36 )
        {
            if( netRiskFormulaBase36 === '' || grossRiskFormulaBase36 === '' )
            {
                setError('Veuillez remplir tous les champs obligatoires !');
                return false;
            }
        }

        // Send the request to the backend in order to persist the data
        try
        {
            await SendPost('api/v1/riskassessment', {
                'financialImpactWeight': financialImpactWeight,
                'imageImpactWeight': imageImpactWeight,
                'criminalImpactWeight': criminalImpactWeight,
                'netRiskFormulaBase36': netRiskFormulaBase36,
                'grossRiskFormulaBase36': grossRiskFormulaBase36,
                'netRiskFormulaBase6': netRiskFormulaBase6,
                'grossRiskFormulaBase6': grossRiskFormulaBase6,
                'formulaBase': formulaBase,
                'creatorId': connectedUser.id
            });

            setError(null);
            setSuccess('Ajouté avec succès.');

            // Clear all the fields after the insertion
            setFinancialImpactWeight('');
            setImageImpactWeight('');
            setCriminalImpactWeight('');
            setNetRiskFormulaBase36('');
            setGrossRiskFormulaBase36('');
            setNetRiskFormulaBase6('');
            setGrossRiskFormulaBase6('');
            setFormulaBase(6);

            navigate(`/riskassessments`);
        }
        catch(error)
        {
            setSuccess(null);
            setError(`Une erreur s'est produite lors de l'action !`);
        }

    }

    const updateRiskAssessment = async () => {

        // Check the inputs for value before sending the request
        if(
            financialImpactWeight === '' ||
            imageImpactWeight === '' ||
            criminalImpactWeight === '')
        {
            setError('Veuillez remplir tous les champs obligatoires !');
            return false;
        }

        if( financialImpactWeight > 1 || financialImpactWeight < 0 || imageImpactWeight > 1 || imageImpactWeight < 0 || criminalImpactWeight > 1 || criminalImpactWeight < 0 )
        {
            setError('La valeur doit être comprise entre 0 et 1.');
            return false;
        }

        if( formulaBase === 6 )
        {
            if( netRiskFormulaBase6 === '' || grossRiskFormulaBase6 === '' )
            {
                setError('Veuillez remplir tous les champs obligatoires !');
                return false;
            }
        }
        else if( formulaBase === 36 )
        {
            if( netRiskFormulaBase36 === '' || grossRiskFormulaBase36 === '' )
            {
                setError('Veuillez remplir tous les champs obligatoires !');
                return false;
            }
        }

        // Send the request to the backend in order to persist the data
        try
        {
            await SendPut(`api/v1/riskassessment/${id}`, {
                'financialImpactWeight': financialImpactWeight,
                'imageImpactWeight': imageImpactWeight,
                'criminalImpactWeight': criminalImpactWeight,
                'netRiskFormulaBase36': netRiskFormulaBase36,
                'grossRiskFormulaBase36': grossRiskFormulaBase36,
                'netRiskFormulaBase6': netRiskFormulaBase6,
                'grossRiskFormulaBase6': grossRiskFormulaBase6,
                'formulaBase': formulaBase
            });

            setError(null);
            setSuccess('Mise à jour effectuée avec succès.');

            navigate(`/riskassessments`);
        }
        catch(error)
        {
            setSuccess(null);
            setError(`Une erreur s'est produite lors de l'action !`);
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div style={styles.formContainer}>

                        <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                        <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Poids de l'impact financier <span style={styles.requiredLabel}>*</span> <span style={{ fontSize: 11, textTransform: 'lowercase', fontWeight: 'bold' }}>( Valeur entre 0 et 1 )</span></span>
                            <input type="text" value={financialImpactWeight} onChange={e => setFinancialImpactWeight(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Poids de l'impact de l'image <span style={styles.requiredLabel}>*</span> <span style={{ fontSize: 11, textTransform: 'lowercase', fontWeight: 'bold' }}>( Valeur entre 0 et 1 )</span></span>
                            <input type="text" value={imageImpactWeight} onChange={e => setImageImpactWeight(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Poids de l'impact criminel <span style={styles.requiredLabel}>*</span> <span style={{ fontSize: 11, textTransform: 'lowercase', fontWeight: 'bold' }}>( Valeur entre 0 et 1 )</span></span>
                            <input type="text" value={criminalImpactWeight} onChange={e => setCriminalImpactWeight(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Base de la formule <span style={styles.requiredLabel}>*</span></span>
                            <select style={styles.selectField} value={formulaBase} onChange={e => setFormulaBase(parseInt(e.target.value))}>
                                <option value={6}>6</option>
                                <option value={36}>36</option>
                            </select>
                        </div>

                        <div style={{ backgroundColor: '#FFFFFF', border: 'solid 1px #275B9F', borderRadius: 20, margin: 10, padding: 20, fontSize: 14 }}>
                            <span style={{ fontWeight: 700}}>Espaces réservés pour les variables: </span>
                            <ul style={{ fontSize: 12 }}>
                                <li>
                                    <b>Risk brut ={">"} </b> <b style={{ color: 'red', fontWeight: 700 }}>:SB</b>
                                </li>
                                <li>
                                    <b>Maitrise ={">"} </b> <b style={{ color: 'red', fontWeight: 700 }}>:MP</b>
                                </li>
                                <li>
                                    <b>Occurrence ={">"} </b> <b style={{ color: 'red', fontWeight: 700 }}>:OC</b>
                                </li>
                                <li>
                                    <b>Impact ={">"} </b> <b style={{ color: 'red', fontWeight: 700 }}>:IM</b>
                                </li>
                            </ul>
                        </div>

                        {   formulaBase === 36 &&
                            <div>
                                <div style={styles.inputRow}>
                                    <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Gross risk formula Base 36 <span style={styles.requiredLabel}>*</span></span>
                                    <input type="text" value={grossRiskFormulaBase36} onChange={e => setGrossRiskFormulaBase36(e.target.value)} style={styles.inputField} />
                                </div>
                                <div style={styles.inputRow}>
                                    <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Net risk formula Base 36 <span style={styles.requiredLabel}>*</span></span>
                                    <input type="text" value={netRiskFormulaBase36} onChange={e => setNetRiskFormulaBase36(e.target.value)} style={styles.inputField} />
                                </div>
                            </div>
                        }

                        {   formulaBase === 6 &&
                            <div>
                                <div style={styles.inputRow}>
                                    <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Gross risk formula Base 6 <span style={styles.requiredLabel}>*</span></span>
                                    <input type="text" value={grossRiskFormulaBase6} onChange={e => setGrossRiskFormulaBase6(e.target.value)} style={styles.inputField} />
                                </div>
                                <div style={styles.inputRow}>
                                    <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Net risk formula Base 6 <span style={styles.requiredLabel}>*</span></span>
                                    <input type="text" value={netRiskFormulaBase6} onChange={e => setNetRiskFormulaBase6(e.target.value)} style={styles.inputField} />
                                </div>
                            </div>
                        }

                        <div style={{ textAlign: 'center', margin: 20 }}>
                            <span onClick={() => navigate("/riskassessments")} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Fermer</span>
                            { !isEditMode && <span onClick={() => createRiskAssessment()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateRiskAssessment()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
