import React, { useState, useEffect } from "react";
import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {isEmpty, SendGet} from "../State/Helper";
import { useAuth } from "../State/useAuth";
import Dashboard from "./Dashboard";
import CircleMenu from "../Components/CircleMenu";
import ListPaginator from "../Components/ListPaginator";
import NotificationBadge from "../Components/NotificationBadge";

export default function List() {

    const [showSideMenu, setShowSideMenu] = useState(true);
    const [riskList, setRiskList] = useState([]);
    const {cartographie, businessUnit, country, company, businessActivity, searchKeyword} = useAuth();

    const styles = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 20
        },
        bodyContainer: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: '#FFFFFF'
        },
        cardRow: {
            display: "flex",
            flexDirection: "row",
        },
    };

    // Get risks list
    async function getRiskList() {

        let response = await SendGet(`api/v1/risks/currentuser`);

        if ( !isEmpty(response.data) ) {

            let parsedCartographie = cartographie.value !== null ? parseInt(cartographie.value) : null;
            let parsedBusinessUnit = businessUnit.value !== null ? parseInt(businessUnit.value) : null;
            let parsedCountry = country.value !== null ? parseInt(country.value) : null;
            let parsedCompany = company.value !== null ? parseInt(company.value) : null;
            let parsedBusinessActivity = businessActivity.value !== null ? parseInt(businessActivity.value) : null;
            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let risks = response.data.filter(item =>
                (parsedCartographie === null || parseInt(item.mappingId) === parsedCartographie) &&
                (parsedBusinessUnit === null || parseInt(item.buId) === parsedBusinessUnit) &&
                (parsedCountry === null || parseInt(item.countryId) === parsedCountry) &&
                (parsedCompany === null || parseInt(item.companyId) === parsedCompany) &&
                (parsedBusinessActivity === null || parseInt(item.baId) === parsedBusinessActivity) &&
                (parseSearchKeyword === null || item.enTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase())) &&
                (parseSearchKeyword === null || item.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()))
            );

            let assessedRisks = [];

            await Promise.all(risks.map(async risk => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            // Remove the none evaluated ones
            let nonEvaluatedRisks = assessedRisks.filter(item => isEmpty(item.calcs));
            let evaluatedRisks = assessedRisks.filter(item => !isEmpty(item.calcs));

            // Sort the array by recently created
            evaluatedRisks = evaluatedRisks.sort((a, b) => b.credate - a.credate);

            assessedRisks = nonEvaluatedRisks.concat(evaluatedRisks);
            setRiskList(assessedRisks);
        }
        else
        {
            setRiskList([]);
        }
    }

    async function getAssessment(riskId) {

        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessments) )
        {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        }
        else
        {
            return null;
        }
    }

    useEffect(() => {
        getRiskList();
    }, [cartographie, businessUnit, country, company, businessActivity, searchKeyword]);

    if( cartographie.value == null )
    {
        return (
            <Dashboard />
        );
    }
    else
    {
        return (
            <div >
                <RiskTopMenu setShowSideMenu={() => setShowSideMenu(!showSideMenu)} showSideMenu={showSideMenu} />
                <div style={styles.bodyContainer}>
                    <div>
                        <RiskSideMenu showSideMenu={showSideMenu} selectedTab="list" />
                    </div>
                    { !isEmpty(riskList) && <ListPaginator items={riskList} itemsPerPage={15} /> }
                    <CircleMenu />
                </div>
            </div>
        );
    }
};
