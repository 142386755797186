import RiskSideMenu from "../Components/RiskSideMenu";
import RiskTopMenu from "../Components/RiskTopMenu";
import {useState} from "react";

export default function LegalEntities() {

    const [showSideMenu, setShowSideMenu] = useState(true);

    const styles = {
        mainContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        mainContent: {
            flex: 5,
            padding: 30,
            display: 'flex',
            flexDirection: 'column'
        },
        bodyContent: {

        },
        mainTitle: {
            fontSize: 26,
            color: '#67D0FA'
        },
        secondaryTitle: {
            fontSize: 26,
            fontWeight: 700,
            color: '#094492'
        },
        cardContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #D1E5FE',
            borderRadius: 10,
            margin: 40,
            padding: 40,
            display: 'flex',
            flexDirection: 'column'
        },
        cardTitle: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#094492',
            fontWeight: 600,
            alignSelf: 'center'
        },
        cardIconContainer: {
          border: 'solid 1px #094492',
          padding: 10,
          width: 30,
          height: 30,
          textAlign: 'center',
          borderRadius: '50%',
          alignSelf: 'center',
            marginBottom: 20
        },
        cardIcon: {
            fontSize: 26,
            color: '#094492',
            marginBottom: 10
        },
        outlineButton: {
            padding: 5,
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #2F87FC',
            color: '#2F87FC',
            fontSize: 14,
            borderRadius: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginRight: 10,
            cursor: 'pointer',
            textTransform: 'uppercase'
        },
        smallOutlineButton: {
            padding: 5,
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #2F87FC',
            color: '#2F87FC',
            fontSize: 11,
            borderRadius: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginRight: 10,
            cursor: 'pointer'
        },
        smallButtonIcon: {
           fontSize: 16,
            verticalAlign: 'middle'
        },
        cardButtonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            marginTop: 20
        },
        sectionContainer: {
            marginLeft: 40
        },
        sectionLabel: {
            color: '#2F87FC',
            fontSize: 12,
            textTransform: 'uppercase',
            fontWeight: 700
        },
        greenPopup: {
            backgroundColor: '#34B764',
            borderRadius: 6,
            color: '#FFFFFF',
            fontSize: 14,
            textTransform: 'uppercase',
            padding: 15,
            display: 'flex',
            flexDirection: 'row'
        }
    }

    return (
        <>
            <RiskTopMenu showSideMenu={showSideMenu} setShowSideMenu={() => setShowSideMenu(!showSideMenu)} />
            <div style={styles.mainContainer}>
                <div>
                    <RiskSideMenu showSideMenu={showSideMenu} selectedTab="" />
                </div>
                <div style={styles.mainContent}>
                    <span style={styles.mainTitle}>Evaluation du risque</span>
                    <span style={styles.secondaryTitle}>Droit de la propriété immobiliere</span>
                    <div style={styles.bodyContent}>

                        <div style={styles.cardContainer}>
                            <div style={styles.cardIconContainer}>
                                <span className="material-symbols-outlined" style={styles.cardIcon}>apartment</span>
                            </div>
                            <span style={styles.cardTitle}>Veuillez choisir un mode d'évaluation</span>
                            <div style={styles.cardButtonsContainer}>
                                <button style={styles.outlineButton}><b>toutes</b> les activites opérationnelles</button>
                                <button style={styles.outlineButton}><b>chaque</b> activite opérationnelle</button>
                            </div>
                        </div>

                        <div style={styles.sectionContainer}>
                            <p style={styles.sectionLabel}>vous évaluez les activites opérationnelles suivantes:</p>
                            <button style={styles.smallOutlineButton}><span className="material-symbols-outlined" style={styles.smallButtonIcon}>apartment</span> Production</button> <button style={styles.smallOutlineButton}><span className="material-symbols-outlined" style={styles.smallButtonIcon}>apartment</span> Vente</button>
                        </div>

                        <div style={styles.cardContainer}>
                            <div style={styles.greenPopup}>
                                <span style={{ fontWeight: 700, fontSize: 12, marginRight: 10, marginTop: 5 }}>dernière évaluation:</span>
                                <div style={{ borderRadius: 5, border: 'solid 1px #FFFFFF', padding: 4, fontSize: 12, marginRight: 5 }}>
                                    <span className="material-symbols-outlined" style={styles.smallButtonIcon}>person</span> Moustakfi walid
                                </div>
                                <div style={{ borderRadius: 5, border: 'solid 1px #FFFFFF', padding: 4, fontSize: 12, marginRight: 5 }}>
                                    <span className="material-symbols-outlined" style={styles.smallButtonIcon}>calendar_month</span> 19.10.2023
                                </div>
                                <div style={{ borderRadius: 5, border: 'solid 1px #FFFFFF', padding: 4, fontSize: 12 }}>
                                    <span className="material-symbols-outlined" style={styles.smallButtonIcon}>av_timer</span> 15:55:54
                                </div>
                            </div>

                            <p style={{ color: '#094492', marginTop: 40 }}><input type="checkbox" /> <span className="material-symbols-outlined" style={{ fontSize: 18, verticalAlign: 'middle', fontWeight: 700, color: '#094492' }}>block</span> Risque non pertinent</p>

                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 2, padding: 10 }}>
                                    <span>
                                        <span className="material-symbols-outlined" style={{ fontSize: 18, verticalAlign: 'middle', fontWeight: 700, color: '#03A9F4' }}>money</span>
                                        <span style={{ fontSize: 14, fontWeight: 700, marginLeft: 20, marginRight: 20, color: '#03A9F4', textTransform: 'uppercase' }}>IMPACT FINANCIER</span>
                                        <span className="material-symbols-outlined" style={{ fontSize: 18, verticalAlign: 'middle', fontWeight: 700, color: '#094492' }}>info</span>
                                    </span>
                                    <input type="number" style={{ padding: 10, paddingLeft: 15, fontSize: 14, marginTop: 10, borderRadius: 20, border: 'solid 1px #03A9F4' }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 2, padding: 10 }}>
                                    <span>
                                        <span className="material-symbols-outlined" style={{ fontSize: 18, verticalAlign: 'middle', fontWeight: 700, color: '#03A9F4' }}>photo</span>
                                        <span style={{ fontSize: 14, fontWeight: 700, marginLeft: 20, marginRight: 20, color: '#03A9F4', textTransform: 'uppercase' }}>IMPACT D'IMAGE</span>
                                        <span className="material-symbols-outlined" style={{ fontSize: 18, verticalAlign: 'middle', fontWeight: 700, color: '#094492' }}>info</span>
                                    </span>
                                    <input type="number" style={{ padding: 10, paddingLeft: 15, fontSize: 14, marginTop: 10, borderRadius: 20, border: 'solid 1px #03A9F4' }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 2, padding: 10 }}>
                                    <span>
                                        <span className="material-symbols-outlined" style={{ fontSize: 18, verticalAlign: 'middle', fontWeight: 700, color: '#03A9F4' }}>balance</span>
                                        <span style={{ fontSize: 14, fontWeight: 700, marginLeft: 20, marginRight: 20, color: '#03A9F4', textTransform: 'uppercase' }}>impact pénal</span>
                                        <span className="material-symbols-outlined" style={{ fontSize: 18, verticalAlign: 'middle', fontWeight: 700, color: '#094492' }}>info</span>
                                    </span>
                                    <input type="number" style={{ padding: 10, paddingLeft: 15, fontSize: 14, marginTop: 10, borderRadius: 20, border: 'solid 1px #03A9F4' }} />
                                </div>
                            </div>

                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                <div style={{ backgroundColor: '#FFE6E6', border: 'solid 1px #792626', borderRadius: 10, padding: 5, paddingLeft: 15, flex: 2, margin: 5, borderTopRightRadius: 0 }}>
                                    <p style={{ color: '#792626', textTransform: 'uppercase', fontSize: 12, fontWeight: 700 }}>désastreux</p>
                                    <p style={{ fontSize: 12, fontWeight: 700, color: '#AF7373' }}> > 500 M£ </p>
                                </div>
                                <div style={{ backgroundColor: '#FFEBE8', border: 'solid 1px #961807', borderRadius: 10, padding: 5, paddingLeft: 15, flex: 2, margin: 5, borderTopRightRadius: 0 }}>
                                    <p style={{ color: '#961807', textTransform: 'uppercase', fontSize: 12, fontWeight: 700 }}>catastrophique</p>
                                    <p style={{ fontSize: 12, fontWeight: 700, color: '#AF7373' }}> > 300 M£ </p>
                                </div>
                                <div style={{ backgroundColor: '#FFF1E8', border: 'solid 1px #94441B', borderRadius: 10, padding: 5, paddingLeft: 15, flex: 2, margin: 5, borderTopRightRadius: 0 }}>
                                    <p style={{ color: '#94441B', textTransform: 'uppercase', fontSize: 12, fontWeight: 700 }}>très sérieux</p>
                                    <p style={{ fontSize: 12, fontWeight: 700, color: '#AF7373' }}> > 100 M£ </p>
                                </div>
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                <div style={{ backgroundColor: '#FEFAF0', border: 'solid 1px #785D15', borderRadius: 10, padding: 5, paddingLeft: 15, flex: 2, margin: 5, borderTopRightRadius: 0 }}>
                                    <p style={{ color: '#7C621D', textTransform: 'uppercase', fontSize: 12, fontWeight: 700 }}>sérieux</p>
                                    <p style={{ fontSize: 12, fontWeight: 700, color: '#7C621D' }}> > 50 M£ </p>
                                </div>
                                <div style={{ backgroundColor: '#F8FFF3', border: 'solid 1px #4C951A', borderRadius: 10, padding: 5, paddingLeft: 15, flex: 2, margin: 5, borderTopRightRadius: 0 }}>
                                    <p style={{ color: '#4E961D', textTransform: 'uppercase', fontSize: 12, fontWeight: 700 }}>modéré</p>
                                    <p style={{ fontSize: 12, fontWeight: 700, color: '#6EAA44' }}> > 10 M£ </p>
                                </div>
                                <div style={{ backgroundColor: '#E9F8EF', border: 'solid 1px #38965C', borderRadius: 10, padding: 5, paddingLeft: 15, flex: 2, margin: 5, borderTopRightRadius: 0 }}>
                                    <p style={{ color: '#1F8746', textTransform: 'uppercase', fontSize: 12, fontWeight: 700 }}>mineur</p>
                                    <p style={{ fontSize: 12, fontWeight: 700, color: '#459D66' }}> {'<'} 10 M£ </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
