import RiskSideMenu from "../Components/RiskSideMenu";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {GetBusinessUnit, GetCompany, SendDelete, SendGet} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function BUCompany() {

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1,
            backgroundColor: "#FFFFFF",
            padding: 20,
            display: 'flex',
            flexDirection: 'column'
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            textTransform: 'uppercase',
            borderRadius: 5,
            padding: 4,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none',
            border: 'solid 1px #000000'
        },
        mainButtonIcon: {
            color: '#000000',
            verticalAlign: 'middle',
            fontSize: 20,
            fontWeight: 700
        },
        cardContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            padding: 15,
            marginBottom: 20,
            borderRadius: 10,
            border: 'solid 1px #E2E2E2'
        },
        mainContainer: {
            marginTop: 20
        },
        flagIcon: {
            width: 50,
            height: 30,
            verticalAlign: 'middle',
            marginRight: 10
        },
        companyLabel: {
            color: '#000000',
            fontWeight: '500'
        },
        actionIcon: {
            fontSize: 22,
            fontWeight: 600,
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            padding: 8,
            borderRadius: '60%',
            marginRight: 10,
        }
    }
    const navigate = useNavigate();
    const [BUCompanies, setBUCompanies] = useState([]);

    const getBUCompanies = async () => {

        try
        {
            const response = await SendGet('api/v1/bu-companies');

            if( response.data !== undefined && Array.isArray(response.data) )
            {
                const buCompanies = response.data;

                await Promise.all(
                    buCompanies.map(async (item, index) => {
                        buCompanies[index].buId = await GetBusinessUnit(item.buId);
                        buCompanies[index].companyId = await GetCompany(item.companyId);
                    })
                );

                setBUCompanies(buCompanies);
            }
        }
        catch(Error)
        {
            console.log(Error);
        }
    }

    const deleteBUcompany = async (id) => {

        if( window.confirm('Do you want to delete the BU company?') )
        {
            try
            {
                await SendDelete(`api/v1/bu-companies/${id}`);
                await getBUCompanies();
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    useEffect(() => {

        getBUCompanies();

    }, []);

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                        <span className="btn-primary-custom" onClick={() => navigate("/BUCompanies/create")} style={styles.mainButton}><span className="material-symbols-outlined" style={styles.mainButtonIcon}>add</span></span>
                    </div>
                    <div style={styles.mainContainer}>
                        {
                            Array.isArray(BUCompanies) && BUCompanies.map(BUcompany => {
                                return (
                                    <div key={BUcompany.id} style={styles.cardContainer}>
                                        <div style={{ flex: 1 }}>
                                            <span style={styles.BUcompanyLabel}>{BUcompany.companyId.name}</span> - <span style={styles.BUcompanyLabel}>{BUcompany.buId.name}</span>
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <Link to={`/BUCompanies/edit/${BUcompany.id}`} style={{ ...styles.actionIconContainer, borderColor: '#094392', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: '#094392' }}>edit</span></Link>
                                            <span onClick={() => deleteBUcompany(BUcompany.id)} style={{ ...styles.actionIconContainer, cursor: 'pointer', borderColor: 'red', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: 'red' }}>delete</span></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
