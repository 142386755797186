import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SendPut } from "../State/Helper";

export default function NotificationComponent({ notification, openObject, closeNotification }) {

    const [activeColor, setActiveColor] = useState(false);

    useEffect(() => {

        switch(notification.notificationType) {
            case 'information':
                setActiveColor({ borderColor: '#106775', mainColor: '#18A2B8', icon: 'info' });
                break;
            case 'danger':
                setActiveColor({ borderColor: '#AB2734', mainColor: '#DC3646', icon: 'report' });
                break;
            case 'warning':
                setActiveColor({ borderColor: '#9F7701', mainColor: '#EAB001', icon: 'warning' });
                break;
            case 'success':
                setActiveColor({ borderColor: '#1A6A2D', mainColor: '#29A746', icon: 'done' });
                break;
            default:
                break;
        }

    }, [notification]);

    const styles = {
        mainContainer: {
            borderRadius: 2,
            backgroundColor: activeColor.mainColor,
            borderLeft: `solid 10px ${activeColor.borderColor}`,
            margin: 20,
            padding: 10,
            display: 'flex'
        },
        title: {
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: 14,
            textTransform: 'uppercase'
        },
        text: {
            color: '#FFFFFF',
            fontSize: 14
        },
        closeIcon: {
            float: 'right',
            color: '#FFFFFF',
            fontSize: 20,
            cursor: 'pointer',
            margin: 20,
        },
        textContainer: {
            flex: 5,
            cursor: 'pointer'
        },
        iconContainer: {
            flex: 1
        },
        sideIconContainer: {
            flex: 0.2,
            justifyContent: 'middle',
            padding: 15
        },
        sideIcon: {
            fontSize: 40,
            color: '#FFFFFF',
            verticalAlign: 'middle',
            textAlign: 'center',
        }
    }

    return (
        <div style={styles.mainContainer}>
            <div style={styles.sideIconContainer}>
                <span style={styles.sideIcon} className="material-symbols-outlined" >{activeColor.icon}</span>
            </div>
            <div style={styles.textContainer} onClick={openObject}>
                <p style={styles.title}>{notification.notificationType}</p>
                <p style={styles.text}>{notification.content}</p>
            </div>
            <div style={styles.iconContainer} onClick={closeNotification}>
                <span className="material-symbols-outlined" style={styles.closeIcon}>close</span>
            </div>
        </div>
    );

}