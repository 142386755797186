import RiskSideMenu from "../Components/RiskSideMenu";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {SendDelete, SendGet} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function TemplateList() {

    const [templates, setTemplates] = useState([]);
    const navigate = useNavigate();

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            marginLeft: 20,
            flex: 1,
            backgroundColor: "#FFFFFF",
            padding: 20,
            display: 'flex',
            flexDirection: 'column'
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            textTransform: 'uppercase',
            borderRadius: 5,
            padding: 4,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none',
            border: 'solid 1px #000000'
        },
        mainButtonIcon: {
            color: '#000000',
            verticalAlign: 'middle',
            fontSize: 20,
            fontWeight: 700
        },
        cardContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            padding: 20,
            marginBottom: 20,
            borderRadius: 10
        },
        mainContainer: {
            marginTop: 20
        },
        flagIcon: {
            width: 50,
            height: 30,
            verticalAlign: 'middle',
            marginRight: 10
        },
        countryLabel: {
            color: '#0943B5',
            fontWeight: 600
        },
        actionIcon: {
            fontSize: 25,
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            border: 'solid 1px #0943B5',
            padding: 8,
            borderRadius: '60%',
            marginRight: 10,
        }
    }

    async function getTemplates() {
        const response = await SendGet(`api/v1/templates`);
        if( response.data !== undefined && Array.isArray(response.data) )
        {
            setTemplates(response.data);
        }
        else
        {
            setTemplates([]);
        }
    }

    async function deleteTemplate (id) {

        if( window.confirm('Voulez-vous supprimer le template?') )
        {
            try
            {
                await SendDelete(`api/v1/templates/${id}`);
                await getTemplates();
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getTemplates()
        .catch(e => console.log(e));
    }, []);

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                        <span onClick={() => navigate("/templates/create")} style={styles.mainButton}><span className="material-symbols-outlined" style={styles.mainButtonIcon}>add</span></span>
                    </div>
                    <div style={styles.mainContainer}>
                        {
                            Array.isArray(templates) && templates.map(item => {
                                return (
                                    <div key={item.id} style={styles.cardContainer}>
                                        <div style={{ flex: 1 }}>
                                            <span style={styles.countryLabel}>{item.label}</span>
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <Link to={`/templates/edit/${item.id}`} style={{ ...styles.actionIconContainer, borderColor: '#094392', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: '#094392' }}>edit</span></Link>
                                            <span onClick={() => deleteTemplate(item.id)} style={{ ...styles.actionIconContainer, cursor: 'pointer', borderColor: 'red', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: 'red' }}>delete</span></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
