import RiskSideMenu from "../Components/RiskSideMenu";
import Success from "../Components/Success";
import Error from "../Components/Error";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getConnectedUser, isEmpty, SendGet, SendPost, SendPut} from "../State/Helper";
import RiskLeveling from "../Components/RiskLeveling";
import ProbabilityLevel from "../Components/ProbabilityLevel";
import {calculateRisk} from "../configuration/riskUtils";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function CreateAssessment() {

    const { riskId, id } = useParams();

    const [isEditMode, setIsEditMode] = useState(false);
    const [version, setVersion] = useState('');
    const [ba, setBa] = useState('');
    const [assessmentDate, setAssessmentDate] = useState('');
    const [irreleventRisk, setIrreleventRisk] = useState('');
    const [financialImpact, setFinancialImpact] = useState('');
    const [imageImpact, setImageImpact] = useState('');
    const [criminalImpact, setCriminalImpact] = useState('');
    const [occurrence, setOccurrence] = useState('');
    const [enComment, setEnComment] = useState('');
    const [frComment, setFrComment] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [assessments, setAssessments] = useState([]);
    const [competence, setCompetence] = useState('');
    const [procedure, setProcedure] = useState('');
    const [tracabilite, setTracabilite] = useState('');
    const [kpi, setKpi] = useState('');
    const [risk, setRisk] = useState(null);
    const [showHelp, setShowHelp] = useState(0);
    const navigate = useNavigate();
    const connectedUser = getConnectedUser();

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1,
            backgroundColor: '#FFFFFF',
            padding: 10
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            height: 35,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        column: {
            flex: 2
        }
    }

    // Check if opened in edit mode
    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/assessments/${id}`);

                if(response.data)
                {
                    setVersion(response.data.version);
                    setBa(response.data.baId);
                    setAssessmentDate(response.data.assesementDate);
                    setIrreleventRisk(response.data.irreleventRisk);
                    setFinancialImpact(response.data.financialImpact);
                    setImageImpact(response.data.imageImpact);
                    setCriminalImpact(response.data.criminalImpact);
                    setOccurrence(response.data.occurrence);
                    setEnComment(response.data.enComment);
                    setFrComment(response.data.frComment);
                    setCompetence(response.data.competence);
                    setProcedure(response.data.procedure);
                    setTracabilite(response.data.tracabilite);
                    setKpi(response.data.kpi);

                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during the getting of the Assessment.`));
    }, []);

    async function getAssessments()
    {
        // Get all assessments for that specific risk
        const assessmentsResponse = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessmentsResponse.data) )
        {
            setAssessments(assessmentsResponse.data);
        }
    }

    // Get the assessments list onload
    useEffect(() => {
        async function fetch() {
            await getRisk();
            await getAssessments();
        }
        fetch();
    }, []);

    // Get risk details
    async function getRisk() {
        const response = await SendGet(`api/v1/risks/${riskId}`);
        if(response.data !== undefined)
        {
            setRisk(response.data);
        }
    }

    // Update risk reevaluate state to 0
    async function updateRisk() {
        await SendPut(`api/v1/risks/${riskId}`, {
            ...risk,
            reevaluate: 0
        });
    }

    // Create new assessment
    const createAssessment = async () => {

        // Check for empty fields
        if(
            isEmpty(occurrence) ||
            isEmpty(competence) ||
            isEmpty(procedure) ||
            isEmpty(tracabilite)
        )
        {
            setError("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        // At least one impact must be choosen
        if( isEmpty(financialImpact) && isEmpty(criminalImpact) && isEmpty(imageImpact) )
        {
            setError(`Au moins un impact doit être rempli!`);
            return;
        }

        // Check maitrise fields
        if( isEmpty(competence) || isEmpty(procedure) || isEmpty(tracabilite) )
        {
            setError(`Veuillez remplir tous les champs obligatoires !`);
            return;
        }

        // Check if the entered values are respecting the allowed values
        if( financialImpact < 0 || financialImpact > 6 || imageImpact < 0 || imageImpact > 6 || criminalImpact < 0 || criminalImpact > 6 || occurrence < 0 || occurrence > 6 || competence < 0 || competence > 6 || procedure < 0 || procedure > 6 || tracabilite < 0 || tracabilite > 6 || kpi < 0 || kpi > 6 )
        {
            setError("Les valeurs doivent être comprises entre 0 et 6.");
            return;
        }

        // Check if impact exists
        if( ( parseFloat(financialImpact) + parseFloat(imageImpact) + parseFloat(criminalImpact) ) <= 0 )
        {
            setError("La valeur de l'impact doit être supérieure à 0");
            return;
        }

        // Check if occurrence exists
        if( occurrence <= 0 )
        {
            setError("La valeur de l'occurrence doit être supérieure à 0.")
            return;
        }

        // Find the current active version of the assessment
        let objectWithMaxVersion = 0;

        if( assessments.length > 0 )
        {
            objectWithMaxVersion = assessments.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);
        }

        let criminalImpactInput = criminalImpact === '' ? 0 : criminalImpact;
        let imageImpactInput = imageImpact === '' ? 0 : imageImpact;
        let financialImpactInput = financialImpact === '' ? 0 : financialImpact;

        // Calculate the risk values
        const calc = await calculateRisk(criminalImpactInput, imageImpactInput, financialImpactInput, competence, procedure, tracabilite, kpi, occurrence);

        console.log('+ Calculation: ', calc);

        // Send the assessment to the backend to be saved
        SendPost(`api/v1/assessments`, {

           version: parseInt(objectWithMaxVersion.version) ? parseInt(objectWithMaxVersion.version) + 1 : parseInt(assessments.length) + 1,
           baId: JSON.parse(localStorage.getItem('businessActivity')).value,
           creatorId: connectedUser.id,
           riskId: riskId,
           assesementDate: Date.now(),
           irreleventRisk: irreleventRisk,
           financialImpact: financialImpactInput,
           imageImpact: imageImpactInput,
           criminalImpact: criminalImpactInput,
           occurrence: occurrence,
           enComment: enComment,
           frComment: frComment,
           competence: competence,
           procedure: procedure,
           tracabilite: tracabilite,
           kpi: kpi,
           riskBrute: parseFloat(calc.riskBrute).toFixed(2),
           riskNet: parseFloat(calc.riskNet).toFixed(2),
           riskBruteNormalise: parseFloat(calc.riskBruteNormalise).toFixed(2),
           riskNetNormalise: parseFloat(calc.riskNetNormalise).toFixed(2),
           impact: parseFloat(calc.impact).toFixed(2),
           maitrise: parseFloat(calc.maitrise).toFixed(2),
           maitrisePercentage: parseFloat(calc.maitrisePercentage).toFixed(2),
           marginProgres: Math.round(calc.marginProgres),
           marginProgresNormalise: Math.round(calc.marginProgresNormalise),
           marginProgresPercentage: parseFloat(calc.marginProgresPercentage).toFixed(2),
           marginProgresPercentageNormalise: parseFloat(calc.marginProgresPercentageNormalise).toFixed(2),
           credate: Date.now()

        })
        .then(async () => {
            await updateRisk();
            navigate(`/risks/detail/${riskId}`);
        })
        .catch(error => {
            setError('Une erreur s\'est produite lors de l\'action.', error);
        });

        // Clean the fields
        setCriminalImpact('');
        setFinancialImpact('');
        setImageImpact('');
        setOccurrence('');
        setEnComment('');
        setFrComment('');
        setCompetence('');
        setProcedure('');
        setTracabilite('');
        setKpi('');
    }

    // Update existing assessment
    const updateAssessment = async () => {

        // Check for empty fields
        if( isEmpty(occurrence) || isEmpty(competence) || isEmpty(procedure) || isEmpty(tracabilite) )
        {
            setError("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        // At least one impact must be chosen
        if ( financialImpact === '' && criminalImpact === '' && imageImpact )
        {
            setError(`au moins un impact doit être rempli!`);
            return;
        }

        // Check if the entered values are respecting the allowed values
        if( financialImpact < 0 || financialImpact > 6 || imageImpact < 0 || imageImpact > 6 || criminalImpact < 0 || criminalImpact > 6 || occurrence < 0 || occurrence > 6 || competence < 0 || competence > 6 || procedure < 0 || procedure > 6 || tracabilite < 0 || tracabilite > 6 || kpi < 0 || kpi > 6 )
        {
            setError("Les valeurs doivent être comprises entre 0 et 6.");
            return;
        }

        // Check if impact exists
        if( ( parseFloat(financialImpact) + parseFloat(imageImpact) + parseFloat(criminalImpact) ) <= 0 )
        {
            setError("La valeur de l'impact doit être supérieure à 0");
            return;
        }

        // Check if occurrence exists
        if( occurrence <= 0 )
        {
            setError("La valeur de l'occurrence doit être supérieure à 0.")
            return;
        }

        // Calculate the risk values
        const calc = await calculateRisk(Math.round(), Math.round(imageImpact), Math.round(financialImpact), Math.round(competence), Math.round(procedure), Math.round(tracabilite), Math.round(kpi), Math.round(occurrence));

        SendPut(`api/v1/assessments/${id}`, {

            version: version,
            baId: ba,
            creatorId: connectedUser.id,
            riskId: riskId,
            assesementDate: assessmentDate,
            irreleventRisk: irreleventRisk,
            financialImpact: financialImpact,
            imageImpact: imageImpact,
            criminalImpact: criminalImpact,
            occurrence: occurrence,
            enComment: enComment,
            frComment: frComment,
            competence: competence,
            procedure: procedure,
            tracabilite: tracabilite,
            kpi: kpi,
            riskBrute: parseFloat(calc.riskBrute).toFixed(2),
            riskNet: parseFloat(calc.riskNet).toFixed(2),
            riskBruteNormalise: parseFloat(calc.riskBruteNormalise).toFixed(2),
            riskNetNormalise: parseFloat(calc.riskNetNormalise).toFixed(2),
            impact: parseFloat(calc.impact).toFixed(2),
            maitrise: parseFloat(calc.maitrise).toFixed(2),
            maitrisePercentage: parseFloat(calc.maitrisePercentage).toFixed(2),
            marginProgres: Math.round(calc.marginProgres),
            marginProgresNormalise: Math.round(calc.marginProgresNormalise),
            marginProgresPercentage: parseFloat(calc.marginProgresPercentage).toFixed(2),
            marginProgresPercentageNormalise: parseFloat(calc.marginProgresPercentageNormalise).toFixed(2)

        })
        .then(() => {
            setSuccess('L\'évaluation a été mise à jour avec succès !');
        })
        .catch(error => {
            setError('Une erreur s\'est produite lors de l\'action.');
        });
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                    </div>
                    <div style={styles.formContainer}>

                        { id && <span style={{ color: '#024194' }}><input type="checkbox" /> <span className="material-symbols-outlined" style={{ verticalAlign: 'middle' }} >block</span> Irrelevant risk</span> }

                        { showHelp === 1 && <RiskLeveling /> }

                        { showHelp === 2 && <ProbabilityLevel /> }

                        <div style={styles.row}>
                            <div style={{ ...styles.inputRow, ...styles.column }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Financial impact</span>
                                <input onMouseEnter={() => setShowHelp(1)} type="text" style={styles.inputField} value={financialImpact} onChange={e => setFinancialImpact(e.target.value)} />
                            </div>
                            <div style={{ ...styles.inputRow, ...styles.column }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Image impact</span>
                                <input onMouseEnter={() => setShowHelp(1)} type="text" style={styles.inputField} value={imageImpact} onChange={e => setImageImpact(e.target.value)} />
                            </div>
                            <div style={{ ...styles.inputRow, ...styles.column }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Criminal impact</span>
                                <input onMouseEnter={() => setShowHelp(1)} type="text" style={styles.inputField} value={criminalImpact} onChange={e => setCriminalImpact(e.target.value)} />
                            </div>
                            <div style={{ ...styles.inputRow, flex: 3 }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Occurrence <span style={styles.requiredLabel}>*</span></span>
                                <input onMouseEnter={() => setShowHelp(2)} type="text" style={styles.inputField} value={occurrence} onChange={e => setOccurrence(e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <div style={{ ...styles.inputRow, flex: 3 }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Compétence <span style={styles.requiredLabel}>*</span></span>
                                <input onMouseEnter={() => setShowHelp(2)} type="text" style={styles.inputField} value={competence} onChange={e => setCompetence(e.target.value)} />
                            </div>

                            <div style={{ ...styles.inputRow, flex: 3 }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Procédure <span style={styles.requiredLabel}>*</span></span>
                                <input onMouseEnter={() => setShowHelp(2)} type="text" style={styles.inputField} value={procedure} onChange={e => setProcedure(e.target.value)} />
                            </div>

                            <div style={{ ...styles.inputRow, flex: 3 }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Traçabilité <span style={styles.requiredLabel}>*</span></span>
                                <input onMouseEnter={() => setShowHelp(2)} type="text" style={styles.inputField} value={tracabilite} onChange={e => setTracabilite(e.target.value)} />
                            </div>

                            <div style={{ ...styles.inputRow, flex: 3 }}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> KPI <span style={styles.requiredLabel}>*</span></span>
                                <input onMouseEnter={() => setShowHelp(2)} type="text" style={styles.inputField} value={kpi} onChange={e => setKpi(e.target.value)} />
                            </div>

                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><img src={require('../assets/images/france.png')} style={{ width: 20, height: 13, borderRadius: 3 }} /> FR Comment</span>
                            <textarea style={styles.inputField} value={frComment} onChange={e => setFrComment(e.target.value)}></textarea>
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><img src={require('../assets/images/britain.png')} style={{ width: 20, height: 13, borderRadius: 3 }} /> EN Comment</span>
                            <textarea style={styles.inputField} value={enComment} onChange={e => setEnComment(e.target.value)}></textarea>
                        </div>

                        <div style={{ textAlign: 'center', margin: 20 }}>
                            <span onClick={() => navigate(`/risks/detail/${riskId}`)} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Fermer</span>
                            { !isEditMode && <span onClick={() => createAssessment()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateAssessment()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
