import {isEmpty} from "../State/Helper";
import React, {useState} from "react";
import {useAuth} from "../State/useAuth";

export default function SearchBox({ isVisible, handleClose }) {

    const styles = {
        container: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        contentStyle: {
            background: '#FFFFFF',
            padding: 30,
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
            position: 'relative',
            color: '#000000'
        },
        closeButtonStyle: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '18px',
            cursor: 'pointer',
        },
        primaryButton: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            fontWeight: 700,
            height: 35,
            padding: 10
        },
        inputSelect: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            width: 250
        },
        iconStyle: {
            fontSize: 18,
            color: '#FFFFFF',
            cursor: 'pointer',
            fontWeight: 700,
            verticalAlign: 'middle',
        }
    }
    const { setSearchKeyword } = useAuth();
    const [keyword, setKeyword] = useState('');

    const searchData = () => {
        if( isEmpty(keyword) )
        {
            setSearchKeyword( { value: "", label: "" } );
        }
        else
        {
            setSearchKeyword( { value: keyword, label: "" } )
        }
    }

    return (
        isVisible &&
        <div style={styles.container} onKeyDown={e => e.key === "Enter" ? searchData() : null} >
            <div style={styles.contentStyle}>
                <span style={styles.closeButtonStyle} onClick={handleClose}>&times;</span>
                <input type="text" placeholder="Mot-clé ..." style={styles.inputSelect} onChange={(e) => setKeyword(e.target.value) } />
                <button onClick={searchData} style={styles.primaryButton}><span className="material-symbols-outlined" style={styles.iconStyle}>search</span></button>
            </div>
        </div>
    )

}