import logoImage from "../assets/images/logo.svg";
import backgroundImage from "../assets/images/background.jpg";
import {useState} from "react";
import { SendPost } from "../State/Helper";
import {useAuth} from "../State/useAuth";

export default function Login() {

    const { login, setCartographie, setBusinessUnit, setCountry, setCompany, setBusinessActivity, setSearchKeyword } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const styles = {
        container: {
            display: 'flex',
            flex: 1,
            height: '98vh',
            backgroundImage: `url("${backgroundImage}")`,
        },
        secondaryContainer: {
            backgroundColor: '#FFFFFF',
            width: 400,
            height: 500,
            margin: 40,
            marginTop: 40,
            borderRadius: 5
        },
        logoContainer: {
            textAlign: 'center',
            paddingTop: 50,
            marginBottom: 20
        },
        logoStyle: {
            width: 200,
            marginBottom: 30
        },
        formContainer: {
            padding: 20,
        },
        iconStyle: {
            color: '#000000',
            fontSize: 20,
            verticalAlign: 'middle',
        },
        inputContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 50
        },
        inputStyle: {
            marginTop: 10,
            fontSize: 14,
            height: 35,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            backgroundColor: '#FFFFFF',
            color: '#000000',
            fontWeight: 700,
            paddingLeft: 38
        },
        inputLabel: {
            color: '#000000',
            fontWeight: 500,
            marginBottom: 20
        },
        forgotPasswordStyle: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            border: 'none',
            textDecoration: 'underline',
            borderRadius: 10,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 3,
            paddingBottom: 3,
            cursor: 'pointer'
        },
        primaryButton: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            fontSize: 13,
            borderRadius: 5,
            fontWeight: 700,
            border: 'solid 1px #000000',
            padding: 5,
            paddingLeft: 10,
            paddingRight: 14,
            paddingTop: 8,
            cursor: 'pointer',
            float: 'right'
        },
        buttonIcon: {
            color: '#000000',
            fontSize: 20,
            verticalAlign: 'middle'
        },
        errorBox: {
            backgroundColor: 'red',
            color: '#FFFFFF',
            textAlign: 'center',
            padding: 20,
            marginBottom: 20,
            borderRadius: 5
        }
    }

    const handleLogin = async () => {

        if( email === '' || password === '' )
        {
            setError('Veuillez remplir tous les champs obligatoires.');
            return;
        }

        try
        {
            const response = await SendPost(`api/v1/auth/authenticate`, {
                "email": email,
                "password": password
            });

            if ( response.data.access_token !== undefined )
            {
                // set the default filters
                setCartographie({ value: null, label: "Tout" });
                setBusinessUnit({ value: null, label: "Tout" });
                setCountry({ value: null, label: "Tout" });
                setCompany({ value: null, label: "Tout" });
                setBusinessActivity({ value: "", label: "Tout" });

                // Global keyword search
                setSearchKeyword({ value: "", label: null });

                localStorage.setItem('cartographie', JSON.stringify({ value: null, label: "Tout" }));
                localStorage.setItem('businessUnit', JSON.stringify({ value: null, label: "Tout" }));
                localStorage.setItem('country', JSON.stringify({ value: null, label: "Tout" }));
                localStorage.setItem('company', JSON.stringify({ value: null, label: "Tout" }));
                localStorage.setItem('businessActivity', JSON.stringify({ value: null, label: "Tout" }));
                localStorage.setItem("searchKeyword", JSON.stringify({ value: null, label: "" }));

                // Using the seperate login function to persist the state
                login(response.data.access_token, response.data.refresh_token, response.data.user);

                // Redirect to the original page the user was trying to visit or to the home page
                window.location.href('/list');
            }
            else
            {
                setError("L'email ou le mot de passe est incorrect.");
            }

        } catch (error)
        {
            setError("L'email ou le mot de passe est incorrect.");
        }
    };

    return (
        <div style={styles.container} onKeyDown={e => e.key === "Enter" ? handleLogin() : null}>
            <div style={styles.secondaryContainer}>
                <div style={styles.logoContainer}>
                    <img src={logoImage} style={styles.logoStyle} alt="logo" />
                </div>
                <div style={styles.formContainer}>
                    { error && <div style={styles.errorBox}>{error}</div> }
                    <div style={styles.inputContainer}>
                        <span className="material-symbols-outlined" style={{ ...styles.iconStyle, marginBottom: -40, zIndex: 999, marginLeft: 10, width: 50 }}>mail</span>
                        <input type="text" style={styles.inputStyle} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                    </div>
                    <div style={styles.inputContainer}>
                        <span className="material-symbols-outlined" style={{ ...styles.iconStyle, marginBottom: -40, zIndex: 999, marginLeft: 10, width: 50 }}>lock</span>
                        <input type="password" style={styles.inputStyle} onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe" />
                    </div>
                    <button style={styles.forgotPasswordStyle} onClick={() => alert(`Veuillez contacter l'administrateur!`)}>Mot de passe oublié ?</button>
                    <button className="btn-primary-custom" onClick={handleLogin} style={styles.primaryButton}><span className="material-symbols-outlined" style={{ ...styles.buttonIcon, marginTop: -3 }}>person</span>Se connecter</button>
                </div>
            </div>
        </div>
    );
}
