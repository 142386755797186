import { useAuth } from "../State/useAuth";

export default function NotificationBadge()
{
    const {searchKeyword, setSearchKeyword} = useAuth();

    const styles = {
        container: {
            backgroundColor: "red", 
            color: "white", 
            verticalAlign: 'middle', 
            borderRadius: 5, 
            padding: 5, 
            fontSize: 14, 
            paddingLeft: 10, 
            paddingRight: 10, 
            cursor: 'pointer', 
            display: 'block', 
            width: 'fit-content', 
            marginBottom: 10
        },
        icon: {
            verticalAlign: 'middle',
            fontSize: 21
        }
    }

    const removeKeyword = () => {
        setSearchKeyword( { value: null, label: "" } );
    }

    if ( searchKeyword.value != null ) {
        return (
            <div style={styles.container} onClick={removeKeyword}>
                { searchKeyword.value } <span className="material-symbols-outlined" style={styles.icon}>close</span>
            </div>
        )
    }
}