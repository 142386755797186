import { SendGet } from "../State/Helper";

export const calculateRisk = async ( criminalImpact, imageImpact, financialImpact, competence, procedure, tracabilite, kpi, occurrence ) => {

    // Convert to integers
    criminalImpact = Number(criminalImpact);
    imageImpact = Number(imageImpact);
    financialImpact = Number(financialImpact);
    occurrence = Number(occurrence);

    // Get the active formula
    let rsp = await SendGet(`api/v1/riskassessment`);

    // Check in case of no formula found
    if( rsp.data === undefined || !Array.isArray(rsp.data) )
    {
        return 0;
    }

    // Get the active formula based on the the biggest id number which means the formula with higher id number is the one used
    let formula = rsp.data.reduce( (max, obj) => parseInt(obj.id) > parseInt(max.id) ? obj : max );

    // Calculate general impact based on the weight on the scale of 6/1
    let impact = 0;

    if ( criminalImpact !== 0 && imageImpact !== 0 && financialImpact !== 0 ) {

        let imageImpactWeight = Number( parseFloat(formula.imageImpactWeight).toFixed(2) );
        let financialImpactWeight = Number( parseFloat(formula.financialImpactWeight).toFixed(2) );
        let criminalImpactWeight = Number( parseFloat(formula.criminalImpactWeight).toFixed(2) );

        impact = ( financialImpact * financialImpactWeight ) + ( criminalImpact * criminalImpactWeight ) + ( imageImpact * imageImpactWeight );

    } else if ( criminalImpact === 0 && imageImpact !== 0 && financialImpact !== 0 ) {

        let adjustedCriminalWeight = Number( parseFloat(formula.criminalImpactWeight).toFixed(2)/2 );
        let imageImpactWeight = Number( parseFloat(formula.imageImpactWeight).toFixed(2) ) + Number( parseFloat(adjustedCriminalWeight).toFixed(2) );
        let financialImpactWeight = Number( parseFloat(formula.financialImpactWeight).toFixed(2) ) + Number( parseFloat(adjustedCriminalWeight).toFixed(2) );

        impact = ( imageImpact * imageImpactWeight ) + ( financialImpact * financialImpactWeight );

    } else if ( criminalImpact !== 0 && imageImpact=== 0 && financialImpact !== 0 ) {

        let adjustedImagelWeight = parseFloat(formula.imageImpactWeight).toFixed(2) /2;
        let criminalImpactWeight = Number( parseFloat(formula.criminalImpactWeight).toFixed(2) ) + Number( parseFloat(adjustedImagelWeight).toFixed(2) );
        let financialImpactWeight = Number( parseFloat(formula.financialImpactWeight).toFixed(2) ) + Number( parseFloat(adjustedImagelWeight).toFixed(2) );

        impact = ( criminalImpact * criminalImpactWeight ) + ( financialImpact * financialImpactWeight );

    } else if ( criminalImpact !== 0 && imageImpact !== 0 && financialImpact === 0 ) {

        let adjustedFinancialWeight = Number(parseFloat(formula.financialImpactWeight /2).toFixed(2));
        let criminalImpactWeight = Number( parseFloat(formula.criminalImpactWeight).toFixed(2) ) + Number( parseFloat(adjustedFinancialWeight).toFixed(2) );
        let imageImpactWeight = Number( parseFloat(formula.imageImpactWeight).toFixed(2) ) + Number( parseFloat(adjustedFinancialWeight).toFixed(2) );

        impact = ( criminalImpact * criminalImpactWeight ) + ( imageImpact * imageImpactWeight );

    } else if ( criminalImpact !== 0 && imageImpact === 0 && financialImpact === 0 ) {

        impact = Number( criminalImpact );

    } else if ( criminalImpact === 0 && imageImpact !== 0 && financialImpact === 0 ) {

        impact = Number( imageImpact );

    } else if ( criminalImpact === 0 && imageImpact === 0 && financialImpact !== 0 ) {

        impact = Number( financialImpact );
    }

    impact = Number( parseFloat(impact).toFixed(2) );

    // Calculate maitrise percentage
    let maitrise = Number( parseFloat(( Number(competence) + Number(procedure) + Number(tracabilite) + Number(kpi) ) / 4).toFixed(2) );
    let maitrisePercentage = Number( parseFloat((maitrise / 6) * 100).toFixed(2) );

    let riskBrute = 0;
    let riskNet = 0;
    let maitriseUpscaled = ( maitrise / 6 ) * 36; // Rescaling the maitrise to scale 36 in order to get accurrate results

    // The dynamic part of the formula
    if ( parseInt(formula.formulaBase) === 6 )
    {
		riskBrute = eval(formula.grossRiskFormulaBase6.replace(/:OC/g, Number( parseFloat(occurrence).toFixed(2) )).replace(/:IM/g, Number( parseFloat(impact).toFixed(2) ))); // RiskBrut = Occurrence * Impact
		riskNet = eval(formula.netRiskFormulaBase6.replace(/:SB/g, Number( parseFloat(riskBrute).toFixed(2) )).replace(/:MP/g, Number( parseFloat(maitriseUpscaled).toFixed(2) )).replace(/:OC/g, Number( parseFloat(occurrence).toFixed(2) )).replace(/:IM/g, Number( parseFloat(impact).toFixed(2) ))); // RiskNet = RiskBrut * maitrise%
	}
    else
    {
        riskBrute = eval(formula.grossRiskFormulaBase6.replace(/:OC/g, Number( parseFloat(occurrence).toFixed(2) )).replace(/:IM/g, Number( parseFloat(impact).toFixed(2) ))); // RiskBrut = Occurrence * Impact
        riskNet = eval(formula.netRiskFormulaBase6.replace(/:SB/g, Number( parseFloat(riskBrute).toFixed(2) )).replace(/:MP/g, Number( parseFloat(maitriseUpscaled).toFixed(2) )).replace(/:OC/g, Number( parseFloat(occurrence).toFixed(2) )).replace(/:IM/g, Number( parseFloat(impact).toFixed(2) ))); // RiskNet = RiskBrut * maitrise%
	}

    let riskBruteNormalise = Number( parseFloat(((riskBrute/36) * 5) + 1).toFixed(2) );
    let riskNetNormalise = Number( parseFloat(((riskNet/36) * 5) + 1).toFixed(2) );
    let marginProgres = Number( Number(parseFloat(riskBrute).toFixed(2)) - Number(parseFloat(riskNet).toFixed(2)) );
    let marginProgresNormalise = Number( parseFloat( Number(parseFloat(riskBruteNormalise).toFixed(2)) - Number(parseFloat(riskNetNormalise).toFixed(2)) ).toFixed(2) );
    let marginProgresPercentage = Number( 1 - Number(parseFloat(maitrisePercentage).toFixed(2)) );
    let marginProgresPercentageNormalise = Number( 1 - Number(parseFloat(maitrisePercentage).toFixed(2)) );

    return {
        riskBrute,
        riskNet,
        riskBruteNormalise,
        riskNetNormalise,
        impact,
        maitrise,
        maitrisePercentage,
        marginProgres,
        marginProgresNormalise,
        marginProgresPercentage,
        marginProgresPercentageNormalise
    };
};
