import RiskSideMenu from "../Components/RiskSideMenu";
import RiskCartographie from "../Components/RiskCartographie";
import {useNavigate} from "react-router-dom";

export default function Activity() {

    const navigate = useNavigate();

    const styles = {
        mainContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        sideMenuContainer: {

        },
        contentContainer: {
            flex: 5,
            display: 'flex',
            flexDirection: 'column',
            padding: 20
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        primaryLabel: {
            fontSize: 28,
            color: '#67D0FA',
            flex: 1
        },
        primaryButton: {
            backgroundColor: '#2F87FC',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            fontWeight: 700,
            fontSize: 13,
            borderRadius: 20,
            border: 'none',
            paddingLeft: 10,
            paddingRight: 15,
            float: 'right',
            textDecoration: 'none',
            verticalAlign: 'middle',
            cursor: 'pointer'
        },
        iconStyle: {
            fontSize: 20,
            fontWeight: 700,
            verticalAlign: 'middle',
            cursor: 'pointer'
        }
    }

    return (
        <div style={styles.mainContainer}>
            <div style={styles.sideMenuContainer}>
                <RiskSideMenu showSideMenu={true} />
            </div>
            <div style={styles.contentContainer}>
                <div style={styles.headerContainer}>
                    <span style={styles.primaryLabel}>Gestion des activités opérationnelles</span>
                    <button onClick={() => navigate("/activities/create")} style={styles.primaryButton}><span className="material-symbols-outlined" style={styles.iconStyle}>add</span> Ajouter</button>
                </div>
                <RiskCartographie textLabel="Production" tagName="Irrigation" onEdit={() => navigate("/activities/edit/1")} onDelete={() => alert("Delete!")} />
                <RiskCartographie textLabel="VENTE" tagName="Irrigation" onEdit={() => alert(0)} onDelete={() => alert("Delete!")} />
            </div>
        </div>
    );
}
