import React from 'react';
import { useAuth } from './useAuth';

const PublicRoute = ({ children, redirectTo }) => {
    const { auth, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.user) {
        window.location.href = '/grid'
        return;
    }

    return children;
};

export default PublicRoute;
